<template>
    <v-container fluid fill-height>
      <v-row align="center" justify="center" class="mx-auto" style="max-width: 960px;">
        <v-col cols="12" sm="4">
          <v-img max-width="123px" alt="erica gilb Verwaltung Logo" src="../../assets/ega-logo.svg"/>
        </v-col>
        <v-col cols="12" sm="8">
          <v-card>
            <v-toolbar color="mainAccent" dark flat>
              <v-toolbar-title>{{ $t('Login') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  :rules="emailRules"
                  :label="$t('email')"
                  prepend-icon="mail"
                  required
                  type="text"
                  v-model="email"
                />
                <v-text-field
                  :rules="passwordRules"
                  :label="$t('password')"
                  prepend-icon="lock"
                  required
                  type="password"
                  v-model="password"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!valid" color="primary" @click="doLogin">{{ $t('LoginNow') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    valid: true,
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    password: '',
    passwordRules: [
      v => !!v || 'Password is required',
    ],
    result: null,
    error_message: '',
    submitted: false
  }),
  created() {
    // reset login status
    this.$store.dispatch('authentication/logout');
  },
  methods: {
    doLogin() {
      this.submitted = true;
      const {email, password} = this;
      const {dispatch} = this.$store;
      if (email && password) {
        dispatch('authentication/login', {email, password});
      }
    }
  }
};
</script>
